import {
  ClickAwayListener,
  Grow,
  MenuList,
  MenuProps,
  Paper,
  Popper,
  PopperPlacementType,
} from '@mui/material';
import React, { forwardRef, SyntheticEvent, useEffect, useState } from 'react';

export interface Props extends Omit<MenuProps, 'open'> {
  align?: 'left' | 'right' | 'top' | 'center';
  maxHeight?: number;
  maxWidth?: number | 'fit-content';
  paperWidth?: number | 'fit-content';
  trigger: (params: { onClick(e: SyntheticEvent): void; isOpen: boolean }) => React.ReactNode;
  disabled?: boolean;
  children: React.ReactNode;
  closeMenuOnItemClick?: boolean;
  margin?: string | number;
}

const popperPlacementLookup: { [key: string]: PopperPlacementType } = {
  right: 'bottom-end',
  left: 'bottom-start',
  top: 'top',
  bottom: 'bottom',
};

const DropdownMenu = forwardRef<HTMLUListElement, Props>(
  (
    {
      align = 'left',
      maxHeight = 500,
      maxWidth = 250,
      paperWidth,
      margin = 0,
      trigger,
      disabled,
      closeMenuOnItemClick = true,
      children,
      ...props
    },
    ref,
  ) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
      setOpen(false);
    };

    useEffect(() => {
      if (!open) return;
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          setOpen(false);
        }
      };
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [open]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      event.currentTarget.focus();
      setAnchorEl(event.currentTarget);
      setOpen(prev => !prev);
    };

    const [portalElement, setPortalElement] = useState<Element>(document.body);
    useEffect(() => {
      if (!open) return;
      // Render the Popper in the last open dialog
      const openDialogs = document.querySelectorAll('.MuiDialog-root');
      setPortalElement(openDialogs?.[openDialogs.length - 1]);
    }, [open]);

    return (
      <>
        {trigger?.({ onClick: handleClick, isOpen: open })}
        <Popper
          disablePortal
          transition
          {...props}
          anchorEl={anchorEl}
          open={disabled ? false : open}
          sx={{
            maxHeight: maxHeight,
            maxWidth: maxWidth,
            zIndex: 1300,
          }}
          placement={popperPlacementLookup[align]}
          container={portalElement}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper
                sx={{
                  margin: margin,
                  minWidth: paperWidth ?? anchorEl?.clientWidth,
                  maxHeight: maxHeight,
                  overflowY: 'scroll',
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList disablePadding ref={ref}>
                    {React.Children.map(children, child => {
                      return (
                        React.isValidElement(child) &&
                        React.cloneElement(child as React.ReactElement, {
                          /*
                           * onKeyDown stopPropagation is needed to stop keyboard shortcuts from working in the MenuList.
                           * Otherwise, if you open a dialog with an inputfield from the MenuList and you press a key that also exists in the MenuList, focus was stolen.
                           */
                          onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) =>
                            e.stopPropagation(),
                          onClick: () => {
                            child.props.onClick?.();
                            if (closeMenuOnItemClick) handleClose();
                          },
                        })
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  },
);

export default DropdownMenu;
