import { DateRangeType } from 'utils/date.utils';

import { SearchState } from 'store/search/search.slice';

import { Option } from 'components/@filters/DropdownFilter';

import { BelgaNowSourceOption } from './belga-now.types';
import { NewsroomLanguages } from './newsroom.types';
import { UserLanguages } from './user.types';

export enum NewsbrandFilterEnum {
  ALL = 'ALL',
  USER = 'USER',
  POPULAR = 'POPULAR',
}

export enum FilterUsage {
  Kiosk = 'kiosk',
  Search = 'search',
  Realtime = 'realtime',
  KioskPressReleases = 'kiosk-pressreleases',
  BelgaNow = 'belganow',
}

export type RealTimeFilters = Partial<{
  newsbrands: NewsbrandFilterEnum;
}>;

export type SearchFilters = Omit<SearchState['filters'], 'period'> & {
  dateRangeType: DateRangeType;
};

export enum KioskFilterKeys {
  Countries = 'countries',
  Languages = 'languages',
}

export type KioskPressReleasesFilters = {
  topics: Option[];
  languages: NewsroomLanguages[];
  following: boolean;
};

export interface KioskFilters {
  [KioskFilterKeys.Countries]: string[];
  [KioskFilterKeys.Languages]: string[];
}

export interface BelgaNowFilters {
  topics: Option<number>[];
  languages: Option<UserLanguages>[];
  sources: BelgaNowSourceOption[];
  editorialType: string;
}

export type CurrentFilters =
  | RealTimeFilters
  | SearchFilters
  | KioskFilters
  | KioskPressReleasesFilters
  | BelgaNowFilters;

export interface CurrentFilter<T = CurrentFilters> {
  id: number;
  currentFilters: T;
}
